import React from "react";
import { Loading, axios, DateTime } from "@/main/";
import { Filter } from "@/comps/home/";
import {
  notification,
  Table,
  Menu,
  Dropdown,
  Modal,
  Input,
  Button,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { custom, getStore, setStore } from "../../main";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "../../translate";
library.add(faSpinner);

class example extends React.Component {
  constructor(props) {
    super(props);
    this.user = getStore("user");

    this.filters = [
      {
        key: 0,
        filter: "all",
        title: "All",
        body: "0",
        icon: "check-circle",
        color: "green",
        href: "#/manage/0/0/0",
      },
      {
        key: 1,
        filter: "received",
        title: "Received",
        body: "0",
        icon: "inbox",
        color: "orange",
        href: "#/manage/1/0/0",
      },
      {
        key: 2,
        filter: "archived",
        title: "Archived",
        body: "0",
        icon: "archive",
        color: "#ed3755",
        href: "#/manage/2/0/0",
      },
      {
        key: 3,
        filter: "complete",
        title: "Completed",
        body: "0",
        icon: "check-square",
        color: "#968fff",
        href: "#/manage/3/0/0",
      },
      {
        key: 4,
        filter: "rejected",
        title: "Rejected",
        body: "0",
        icon: "window-close",
        color: "#8444F5",
        href: "#/manage/4/0/0",
      },
      {
        key: 5,
        filter: "sent",
        title: "Sent",
        body: "0",
        icon: "paper-plane",
        color: "#39A2DB",
        href: "#/manage/5/0/0",
      },
      {
        key: 6,
        filter: "cancelled",
        title: "Canceled",
        body: "0",
        icon: "ban",
        color: "#fc0328",
        href: "#/manage/6/0/0",
      },
    ];
    this.filters_map = {
      received: 1,
      all: 0,
      archived: 2,
      complete: 3,
      cancelled: 6,
      sent: 5,
      rejected: 4,
    };
  }
  calc_link = (a) => {
    let { params } = this.props.match;
    return `#/manage/${params.filter}/${a.type}/${a.id}`;
  };

  handlePreview = (url, txin) => {
    setStore("txin", txin);
    window.location.href = url;
  };
  state = {
    loading: false,
    with_count: 1,
    visible: false,
    modalLoading: false,
    selectedId: 0,
    reason: "",
    namefilter: "",
  };
  table = {
    bordered: 1,
    size: "small",
    page: {
      page: 0,
      pper: 8,
    },
    columns: [
      { title: "#", dataIndex: "index", key: "key", width: 80 },
      {
        field: "Filename",
        dataIndex: "name",
        key: "subject",
        width: 300,
        render: (name) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {name}
          </div>
        ),
        sorter: (a, b) => 1,
        // sortOrder: ["ascend", "descend"]
      },
      {
        field: "From",
        dataIndex: "sender",
        key: "from",
        render: (sender) => <span>{sender.kdss_name ?? ""}</span>,
      },
      {
        field: "Status",
        dataIndex: "",
        key: "status",
        width: 150,
        render: (per, i) => {
          let key = per.status.toLowerCase();
          let active =
            key === "waiting"
              ? { ...this.filters[1], title: "waiting" }
              : this.filters[this.filters_map[key]];
          let title = active?.title;
          let icon = { icon: active?.icon, color: active?.color };

          if (
            per.waiting_for_userid &&
            per.waiting_for_userid === this.user?.kdss_user
          ) {
            title = "ACT NOW";
            icon.icon = "clipboard-check";
            icon.color = "#46a642";
          }

          if (per.status === "PROCESSING") {
            title = "In progress";
            icon.icon = "spinner";
            icon.color = "orange";
          }

          return (
            <div className="status_container">
              <FontAwesomeIcon icon={["fas", icon.icon]} color={icon.color} />
              &nbsp;&nbsp;
              <span style={{ textTransform: "capitalize" }}>{title}</span>
            </div>
          );
        },
      },
      {
        field: "Lastchange",
        dataIndex: "modified_dt",
        key: "modified_dt",
        width: 160,
        render: (per) => {
          return <DateTime value={per} />;
        },
        sorter: (a, b) => {
          let d1 = new Date(a).getTime();
          let d2 = new Date(b).getTime();
          return d1 >= d2 ? 1 : -1;
        },
      },
      {
        field: "Action",
        key: "action",
        width: 100,
        render: (per) => {
          const menu = (
            <Menu>
              <Menu.Item>
                {/* onClick={()=>window.alert("ASda")} */}
                <div
                  onClick={() =>
                    this.handlePreview(
                      this.calc_link({ type: "preview", id: per.docid }),
                      per.txnid
                    )
                  }
                >
                  Preview
                </div>
              </Menu.Item>
              <Menu.Item>
                <a href={this.calc_link({ type: "share", id: per.docid })}>
                  Share
                </a>
              </Menu.Item>
              <Menu.Item onClick={() => setStore("filename", per)}>
                <a href={this.calc_link({ type: "download", id: per.docid })}>
                  Download
                </a>
              </Menu.Item>
              {this.user?.kdss_user === per.sent_by_userid &&
                per.status === "WAITING" && (
                  <Menu.Item
                    onClick={() => {
                      this.setState({ selectedId: per.docid });
                      this.funcs.close();
                    }}
                  >
                    {/* <a href={this.calc_link({ type: "cancel", id: per.docid })}> */}
                    Cancel
                    {/* </a> */}
                  </Menu.Item>
                )}
            </Menu>
          );
          return (
            <Dropdown trigger={["click"]} overlay={menu}>
              <div
                style={{ padding: "5px 0" }}
                className="btn flex_center"
                bg_color="5"
                onClick={this.funcs.new}
              >
                Action
              </div>
            </Dropdown>
          );
        },
      },
    ],
    pagination: {
      current: 1,
      pageSize: 8,
      total: 0,
      showSizeChanger: false,
      onChange: (page) => {
        this.table.pagination.current = page;
        this.funcs.init();
      },
    },
  };
  funcs = {
    renderTotals: (key, data) => {
      let filter = key === "all" ? "total" : key;
      if (data.list_count[filter]) {
        //console.log("thisis", data.list_count[filter]);
        return data.doccount;
      } else return 0;
    },
    init: (sort = "", order = "") => {
      this.setState({ loading: true });
      let { filter } = this.props.match.params;
      let { current, pageSize } = this.table.pagination;
      let json = {
        with_count: 1,
        page: current - 1,
        pper: pageSize,
        status: this.filters[filter].filter,
        sort: order === "" ? "" : sort,
        sort_by: order,
        namefilter: encodeURIComponent(this.state.namefilter),
      };
      axios
        .post("client/api/doc/list", json)
        .then(({ data }) => {
          let { filters } = this;
          let a = data.list_count;
          let { current, pageSize, total } = this.table.pagination;
          data.list.map((per, i) => {
            per.index = (current - 1) * pageSize + i + 1;
            per.key = per.index;
          });
          this.table.dataSource = data.list;
          // this.table.pagination.total = data?.list?.length;
          this.table.pagination.total = this.funcs.renderTotals(
            json.status,
            data
          );
          if (a) {
            filters[0].body = a.total;
            filters[1].body = a.received;
            filters[2].body = a.archived;
            filters[3].body = a.complete;
            filters[4].body = a.rejected;
            filters[5].body = a.sent;
            filters[6].body = a.cancelled ?? 0;
          }
          this.setState({ loading: false, with_count: 1 });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
          // notification["error"]({
          //   // message: data.message,
          //   // description: e.data.message,
          // });
        });
    },
    close: () => {
      this.setState({ visible: !this.state.visible });
    },
    cancelDoc: () => {
      let id = this.state.selectedId;
      let reason = this.state.reason;
      this.setState({ modalLoading: true });
      axios
        .post("client/api/doc/cancel", { docid: id, reason })
        .then(() => {
          // this.table.pagination.current = 1;
          this.funcs.init();
          notification.success({ message: "Амжилттай!" });
        })
        .catch(({ data }) => {
          notification.error({ message: data.message });
        })
        .finally((res) => {
          this.setState({ modalLoading: false });
          this.funcs.close();
        });
      this.setState({ reason: "" });
    },
  };
  componentDidMount() {
    this.funcs.init();
  }
  componentDidUpdate(pp, ps) {
    if (pp.match.params.filter !== this.props.match.params.filter) {
      this.table.pagination.current = 1;
      this.funcs.init();
    }
  }
  handleSearch = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    this.funcs.init(); // Call your search function
  };

  render() {
    let { loading, with_count } = this.state;
    let style = { marginRight: 10 };
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

    return (
      <div className="manage_router_container">
        <Modal
          title="Document canceling confirmation"
          visible={this.state.visible}
          onCancel={this.funcs.close}
          footer={null}
        >
          {this.state.modalLoading && (
            <div className="loader">
              <LoadingOutlined />
            </div>
          )}
          <span style={{ fontSize: "12px" }}>Cancel description</span>
          <Input.TextArea
            onChange={(e) => {
              this.setState({ reason: e.target.value });
              e.target.value = "";
            }}
            value={this.state.reason}
            style={{ marginBottom: "15px", minHeight: "100px" }}
            placeholder="Type here why you are cancelling this document ... "
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>Do you really want to cancel this document ?</p>
            <div>
              <button
                onClick={this.funcs.cancelDoc}
                bg_color="5"
                className="btn action_button"
              >
                Cancel this doc
              </button>
            </div>
          </div>
        </Modal>
        <Loading loading={loading && with_count} />
        <div className="absfull">
          <div className="home_filter_container">
            {this.filters.map((per, i) => {
              per.size = "small";
              per.active = 0;
              if (i == this.props.match.params.filter) {
                per.active = 1;
              }
              return (
                <Filter
                  key={per.key}
                  style={style}
                  data={per}
                  name={Translate[ln].Manage.Filters[per.title]}
                />
              );
            })}
          </div>
          <div className="home_filter_table">
            <form onSubmit={this.handleSearch}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  paddingBottom: "10px",
                }}
              >
                <Input
                  placeholder={Translate[ln].Manage.Search.Placeholder}
                  onChange={(e) =>
                    this.setState({ namefilter: `${e.target.value}`.trim() })
                  }
                />{" "}
                <Button
                  onClick={() => this.funcs.init()}
                  loading={this.state.loading}
                >
                  {Translate[ln].Manage.Search.Button}
                </Button>
              </div>
              <Table
                {...this.table}
                loading={loading}
                scroll={{ x: true }}
                onChange={(a, b, c, d) => {
                  this.funcs.init(c.field, c.order);
                }}
                columns={this.table.columns.map((e, i) => ({
                  ...e,
                  title: e.title || Translate[ln].Manage.Table[e.field],
                }))}
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default example;
