import React from "react";
import { Document, Page } from "react-pdf";
import { axios, custom, Loading } from "@/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Select, notification } from "antd";
import Drag from "./upload/Drag";
import { createRef } from "react";
import {
  CheckSquareOutlined,
  CloseCircleOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  InfoCircleOutlined,
  EditTwoTone,
  DeleteTwoTone,
} from "@ant-design/icons";
import { Stage, Layer, Text } from "react-konva";

import { StickyNote } from "./sticky/StickyNote";
import { Translate } from "../translate";
import { getStore } from "../main";

class example extends React.Component {
  constructor(props) {
    super(props);
    this.overlayRef = createRef();
  }
  state = {
    loader: true,
    currentUrl: "",
    drag_start: false,
    user_me: getStore("user"),
    coords: {},
    myCoords: [],
    selectedCoord: -1,
    stamp_name: "",
    stamp_count: 0,
    hoveredOption: null,
    deleteHovered: false,
    checkDelete: false,
    reload: 0,
    active_i: 0,
    activepage: 1,
    numPages: 0,
    rotate: 0,
    scale: 100,
    visible: false,
    transform: { scale: 1, rotate: 0 },
    max: {
      w: 0,
      h: 0,
    },
  };
  pageChange = (page) => {
    if (this.props.pageChange) {
      this.props.pageChange(page);
    }
  };

  intersect = function (a, b) {
    return !(a.x2 <= b.x1 || b.x2 <= a.x1 || a.y2 <= b.y1 || b.y2 <= a.y1);
  };
  calc_intersect = (active) => {
    let boxes = [...this.props.boxes];
    boxes.push({
      left: 10,
      top: 966,
      page: this.state.activepage,
      style: { height: 18, width: 255 },
    });

    let i = 0;
    let inter = false;
    while (boxes.length > i) {
      let per = boxes[i];
      if (i !== this.state.active_i && per.page === this.state.activepage) {
        let box1 = {
          x1: per.left,
          x2: per.left + per.style.width,
          y1: per.top,
          y2: per.top + per.style.height,
        };
        let box2 = {
          x1: active.left,
          x2: active.left + active.style.width,
          y1: active.top,
          y2: active.top + active.style.height,
        };
        if (this.intersect(box1, box2)) {
          inter = true;
          break;
        }
      }
      i++;
    }
    return inter;
  };
  reload = () => {
    this.setState({ reload: this.state.reload + 1 });
  };

  mouseMove = (e) => {
    let stamps = this.props.boxes;
    let { offsetX, offsetY } = e.nativeEvent;
    let active = stamps[this.state.active_i];
    let per = { ...{}, ...active };
    let inter = false;
    let act = this.active_ref;

    if (per && act) {
      let { clientWidth, clientHeight } = this.overlayRef.current;

      let x1 = offsetX - parseInt(act.getAttribute("shiftx"));
      let y1 = offsetY - parseInt(act.getAttribute("shifty"));
      if (clientWidth < x1 + act.clientWidth) {
        // Parent Right side limitation
        x1 = clientWidth - act.clientWidth;
      }
      if (clientHeight < y1 + act.clientHeight) {
        // Parent Bottom side limitation
        y1 = clientHeight - act.clientHeight;
      }
      if (x1 < 0) {
        // Parent Left side limitation
        x1 = 0;
      }
      if (y1 < 0) {
        // Parent Top side limitation
        y1 = 0;
      }
      per.left = x1;
      per.top = y1;
      inter = this.calc_intersect(per); // Intersect check
      if (inter) {
        per = { ...{}, ...active };
        per.left = x1;
        inter = this.calc_intersect(per); // Fixed left side
        if (inter) {
          per = { ...{}, ...active };
          per.top = y1;
          inter = this.calc_intersect(per); // Fixed top side
          if (inter) {
            per = { ...{}, ...active };
          }
        }
      }
    }

    per.page = this.state.activepage;
    stamps[this.state.active_i] = per;
    this.reload();
  };
  mouseUp = (e) => {
    let container = this.overlayRef.current;
    container = container.getBoundingClientRect();
    let stamps = this.props.boxes;
    let per = { ...stamps[this.state.active_i] };

    per.x1 = per.left;
    per.y1 = container.height - per.top - per.height;
    // per.x2 = per.left + per.width;
    // per.y2 = container.height - per.top;

    stamps[this.state.active_i] = per;
    this.props.setBoxes(stamps);
    this.overlayRef.current.style.display = "none";
  };
  mouseDown = (ref, i) => {
    this.props.setActive && this.props.setActive(i);
    //console.log("mouse down", i);
    this.overlayRef.current.style.display = "block";
    this.active_ref = ref;
    this.setState({ active_i: i });
  };

  // onDragEnd = (event, per) =>{
  //   let container = document.querySelector(".app");
  //   container = container.getBoundingClientRect()

  //   console.log(event, per)
  //   let tmp = {...per, left: event.clientX - container.left, top: event.clientY - container.top, key: per.name+ this.state.reload, unique: per.name + this.state.reload,};
  //   this.stamps.push(tmp);
  //   this.reload()
  //   console.log(this.stamps)

  // }

  setMaxBounds = () => {
    const container = document.querySelector(".upload_steps_body");
    const sides = container.getBoundingClientRect();
    this.setState({ max: { w: sides.width, h: sides.height } });
  };
  // componentDidMount() {
  //   const setMaxBounds = () => {
  //     const container = document.querySelector(".upload_steps_body");
  //     console.log("container", container.clientHeight, container.clientWidth);
  //   };

  //   setTimeout(setMaxBounds, 500);
  // }
  componentDidMount() {
    this.fetchCoords();
    //console.log("this.state here in didmount", this.state);
    //console.log("this.props here in didmount", this.props);
    const url = window.location.href;
    this.setState({ currentUrl: url });
    //this.props.setBoxes([]);
  }
  componentDidUpdate(prevProps) {
    {
      if (this.props.boxes?.length !== prevProps.boxes?.length) {
        let boxes = this.props.boxes;
        if (this.props.boxes?.length > 0) {
          if (boxes[this.props.boxes?.length - 1]?.sumx != 1) {
            boxes[this.props.boxes?.length - 1].page = this.state.activepage;
          }
        }
        this.props.setBoxes(boxes);

        this.getCoord();
      }
    }

    if (prevProps.saveUp !== this.props.saveUp) {
      this.getCoord();
    }

    if (prevProps.file !== this.props.file) {
      //console.log("file changed here!", this.props.file);
      this.setMaxBounds();
    }
    //console.log("this.state here in didUpdate", this.state);
    //console.log("this.props here in didUpdate", this.props);
  }

  infoHandler = () => {
    this.setState({ visible: !this.state.visible });
  };

  updateHandler = (e, i) => {
    let rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left; //x position within the element.
    let y = e.clientY - rect.top;
    const tmp = this.props.boxes;
    tmp.map((item, idx) => {
      if (i === idx) {
        item["width"] = rect.width;
        item["height"] = rect.height;
        item["value"] = e.target.value;
      }
      return item;
    });
    //console.log("updatehandler worked here tmp", tmp);
    this.props.setBoxes(tmp);
  };
  getCoord = () => {
    let temp = {};
    let cnt = 0;
    let i = 0;
    console.log("current boxes", this.props.boxes);
    while (i < this.props.boxes.length) {
      cnt++;

      if (
        this.props.boxes[i].unique_key.toString() == "yourSignature" ||
        this.props.boxes[i].unique_key.toString() == "yourStamp"
      ) {
        console.log("get yoursignature");
        temp[i] = {
          color: this.props.boxes[i].color,
          description: this.props.boxes[i].description,
          drag_desc: this.props.boxes[i].drag_desc,
          drag_txt: this.props.boxes[i].drag_txt,
          height: this.props.boxes[i].height,
          icon: this.props.boxes[i].icon,
          imageid: this.props.boxes[i].imageid,
          imagetype: this.props.boxes[i].imagetype,
          left: this.props.boxes[i].left,
          msisdn: this.props.boxes[i].msisdn,
          page: this.props.boxes[i].page,
          part_index: this.props.boxes[i].part_index,
          shortname: this.props.boxes[i].shortname,
          style: this.props.boxes[i].style,
          svgurl: this.props.boxes[i].svgurl,
          top: this.props.boxes[i].top,
          type: this.props.boxes[i].type,
          uid: this.props.boxes[i].uid,
          unique_key: this.props.boxes[i].unique_key,
          value: this.props.boxes[i].value,
          width: this.props.boxes[i].width,
          x1: this.props.boxes[i].x1,
          y1: this.props.boxes[i].y1,
        };
      } else {
        temp[i] = {
          top: this.props.boxes[i].top,
          left: this.props.boxes[i].left,
          x1: this.props.boxes[i].x1,
          y1: this.props.boxes[i].y1,
          page: this.props.boxes[i].page,
          color: this.props.boxes[i].color,
          drag_txt: this.props.boxes[i].drag_txt,
          height: this.props.boxes[i].height,
          icon: this.props.boxes[i].icon,
          key: this.props.boxes[i].key,
          name: this.props.boxes[i].name,
          style: this.props.boxes[i].style,
          type: this.props.boxes[i].type,
          //uid: this.props.boxes[i].uid,
          unique_key: this.props.boxes[i].unique_key,
          value: this.props.boxes[i].value,
          width: this.props.boxes[i].width,
          part_index: this.props.boxes[i].part_index,
        };
      }

      i++;
    }
    //console.log("tempx", temp);
    // console.log("boexes", this.props.boxes);
    this.setState({ coords: temp });
    this.setState({ stamp_count: cnt });

    //const res = await axios.post(`/client/api/user/create/coord`,{});
    //console.log("theres", res);
  };
  handleSelectChange = (value) => {
    const selectedOptionIndex = this.state.myCoords.findIndex(
      (coord) => coord.name === value
    );
    //console.log("this is the option", selectedOptionIndex);

    this.setState({ selectedCoord: selectedOptionIndex });
    if (this.state.checkDelete) {
      //
      this.deleteCoord(selectedOptionIndex);
      this.setState({ selectedCoord: null });
    } else {
      this.positionStamps(selectedOptionIndex);
    }
  };
  saveCoord = async () => {
    let temp = true;
    //console.log("mycoords", this.state.myCoords);
    if (
      this.state.myCoords &&
      this.state.stamp_name.length > 0 &&
      this.state.stamp_name.length < 36
    ) {
      this.state.myCoords.map((val) => {
        if (this.state.stamp_name == val.name) {
          notification["error"]({
            message: "Name exists!!!",
          });
          temp = false;
          return;
        }
      });
    }
    if (
      this.state.stamp_name.length > 0 &&
      temp &&
      this.state.stamp_name.length < 36
    ) {
      try {
        console.log("name", this.state.stamp_name);
        console.log("stateid", this.state.user_me.kdss_user);
        console.log("coords", this.state.coords);

        const res = await axios.post(`/client/api/user/create/coord`, {
          user_id: this.state.user_me.kdss_user,
          name: this.state.stamp_name,
          json_data: this.state.coords,
        });
        //console.log("theres", res);
        notification.success({ message: "Saved" });

        this.fetchCoords();
      } catch (error) {
        console.log("in error", error);
      }
    } else if (temp && this.state.stamp_name.length == 0) {
      temp = false;
      notification["error"]({
        message: "Enter name",
      });
    } else if (temp && this.state.stamp_name.length > 35) {
      notification["error"]({
        message: "Name too long, length 35",
      });
    }
  };
  fetchCoords = async () => {
    try {
      let temp = [];
      const res = await axios.post(`/client/api/user/get/coords`, {
        user_id: this.state.user_me.kdss_user,
      });
      const resData = res?.data;
      //temp = Object.values(resData);
      console.log("theresss", res?.data);

      if (resData) {
        for (const [key, value] of Object.entries(resData)) {
          if (key.toString() != "code" && key.toString() != "message") {
            temp.push(value);
          }
        }
      }
      this.setState({ myCoords: temp });
      let tempx = JSON.parse(temp[0].json_data);
      //console.log("tempx", tempx);

      //console.log("resulting arrya", temp);
    } catch (error) {
      if (error.status == 461) {
        this.setState({ myCoords: [] });
      }
      console.log("coord fetch error: empty fix here be", error);
    }
  };
  deleteCoord = async (val) => {
    try {
      let temp = [];
      //console.log("delete in prog all", this.state.myCoords);
      console.log(
        "delete in prog",
        this.state.myCoords[this.state.selectedCoord]
      );
      const res = await axios.post(`/client/api/user/delete/coord`, {
        id: this.state.myCoords[val].id,
      });

      //temp = Object.values(resData);
      //console.log("delete response", res);
      notification.success({ message: "Deleted" });

      this.fetchCoords();
    } catch (error) {
      console.log("coord fetch error", error);
    }
  };

  positionStamps = (val) => {
    const allUserx = this.props.allUsers;

    // Parse the JSON data and extract values
    let vals = Object.values(JSON.parse(this.state.myCoords[val].json_data));
    const boxes = [];
    const numofUsers = vals.filter((el) => el.name == "Signature");
    //console.log("the num of users", numofUsers, allUserx);

    let counter = [];
    vals.map((el) => {
      counter.push(el.part_index);
    });
    const allsparts = new Set(counter);
    const numberOfParts = allsparts.size;
    console.log("check number here", numberOfParts, allUserx.length);
    console.log("check users here", numberOfParts, allUserx);

    //now need to check if stamps are matching with at least some of users
    //check if part index matches with the stamp

    if (numberOfParts <= allUserx.length) {
      for (let i = 0; i < vals.length; i++) {
        if (
          vals[i].unique_key.toString() == "yourSignature" ||
          vals[i].unique_key.toString() == "yourStamp"
        ) {
          let {
            color,
            description,
            drag_desc,
            drag_txt,
            height,
            icon,
            imageid,
            imagetype,
            left,
            msisdn,
            page,
            part_index,
            shortname,
            style,
            svgurl,
            top,
            type,
            uid,
            unique_key,
            value,
            width,
            x1,
            y1,
          } = vals[i];

          if (msisdn != allUserx[part_index].msisdn) {
            notification["error"]({
              message: "user order not matching",
            });
            return;
          }
          let sumx = 1;
          boxes.push({
            sumx,
            color,
            description,
            drag_desc,
            drag_txt,
            height,
            icon,
            imageid,
            imagetype,
            left,
            msisdn,
            page,
            part_index,
            shortname,
            style,
            svgurl,
            top,
            type,
            uid,
            unique_key,
            value,
            width,
            x1,
            y1,
          });
        } else {
          let {
            left,
            top,
            x1,
            y1,
            page,
            drag_txt,
            name,
            icon,
            key,
            unique_key,
            style,
            type,
            //uid,
            width,
            height,
            value,
            color,
            part_index,
          } = vals[i];
          //console.log("getting page", page, i);
          //let page = 3;
          let sumx = 1;
          //let part_index = i + 1;
          let uid = allUserx[part_index].uid;
          let msisdn = allUserx[part_index].msisdn;
          let username = allUserx[part_index].name;

          boxes.push({
            ...{
              username,
              sumx,
              uid,
            },
            ...{
              name,
              icon,
              key,
              unique_key,
              drag_txt,
              style,
              type,
              //uid,
              width,
              height,
              value,
              left,
              top,
              x1,
              y1,
              page,
              part_index,
              color,
              msisdn,
            },
          });
        }
      }
    } else {
      notification["error"]({
        message: "participant missing",
      });
      //this.setState({ selectedCoord: null });
      //return;
    }

    console.log("Final boxes before setBoxes", boxes);

    this.props.setBoxes(boxes);

    // Additional logging to check the state before and after setting boxes
  };

  handleOptionHover = (option) => {
    this.setState({ hoveredOption: option });
    this.setState({ deleteHovered: true });
  };

  handleOptionLeave = () => {
    this.setState({ hoveredOption: null });
    this.setState({ deleteHovered: false });
  };
  handlecheckHover = () => {
    //console.log("heyhovering1", this.state.checkDelete);
    this.setState({ checkDelete: true });
    //console.log("heyhovering2", this.state.checkDelete);
  };

  handlecheckLeave = () => {
    //console.log("heyleaving1", this.state.checkDelete);
    this.setState({ checkDelete: false });
    //console.log("heyleaving2", this.state.checkDelete);
  };

  pdfTransformer = (scale) => {
    const t = { ...this.state.transform };
    if (scale > 0.2 && scale < 3) {
      t.scale = scale;
    }
    // if (rotate) {
    //   t.rotate = rotate;
    // }

    const container = document.querySelector(".my_pdf");
    container.style.zoom = t.scale;

    this.setState({ transform: t });
  };

  render() {
    let { user, parts, roles, lockInputs } = this.props;
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

    return (
      <div className="review_root">
        <div className="review_pdf_fixed_header">
          <EditTwoTone
            onClick={this.infoHandler}
            style={{
              fontSize: "200%",
              paddingTop: "10px",
              paddingLeft: "10px",
            }}
          ></EditTwoTone>

          <div className="review_pdf_tools">
            <div
              className="review_pdf_container_back"
              onClick={() => {
                if (this.state.activepage > 1) {
                  this.setState({ activepage: this.state.activepage - 1 });
                  this.pageChange(this.state.activepage - 1);
                }
              }}
            >
              <FontAwesomeIcon size="3x" icon="caret-left" />
            </div>
            <div className="review_pdf_container_total">
              <div>{Translate[ln].NewDoc.Customizations.Page}:</div>{" "}
              &nbsp;&nbsp;
              <Input
                onFocus={(event) => {
                  event.target.select();
                }}
                style={{ width: 40, textAlign: "center" }}
                value={this.state.activepage}
                onChange={(data) => {
                  let val = data.target.value * 1;
                  if (!isNaN(val) && val > 0 && this.state.numPages >= val) {
                    this.setState({ activepage: val });
                    this.pageChange(val);
                  }
                }}
              />
              &nbsp; <div>of</div>&nbsp;<b>{this.state.numPages}</b>
            </div>
            <div
              className="review_pdf_container_forward"
              onClick={() => {
                if (this.state.numPages > this.state.activepage) {
                  this.setState({ activepage: this.state.activepage + 1 });
                  this.pageChange(this.state.activepage + 1);
                }
              }}
            >
              <FontAwesomeIcon size="3x" icon="caret-right" />
            </div>

            {this.state?.currentUrl?.includes("upload/2") ? (
              <div
                style={{
                  //marginLeft: "15%",
                  paddingTop: 15,
                  display: "flex",
                  width: "60%",
                  //backgroundColor: "yellow",
                }}
              >
                {this.state.stamp_count ? (
                  <div
                    style={{
                      width: "70%",
                      //backgroundColor: "yellow",
                      display: "flex",
                      //alignItems: "center", // Center items vertically
                      justifyContent: "center",
                    }}
                  >
                    <Input
                      placeholder="name"
                      style={{
                        width: "100%",
                        height: "72%",
                        textAlign: "center",
                      }}
                      maxLength={35}
                      value={this.state.stamp_name}
                      onChange={(data) => {
                        this.setState({ stamp_name: data.target.value });
                      }}
                    ></Input>
                    <Button onClick={() => this.saveCoord()}>Save</Button>{" "}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "70%",
                      height: "72%",
                      textAlign: "center",
                    }}
                  ></div>
                )}

                <Select
                  placeholder="Select"
                  style={{
                    marginLeft: "10%",
                    textAlign: "center",
                    width: "70%",
                    //backgroundColor: "green",
                  }}
                  onChange={this.handleSelectChange}
                  //onSelect={this.handleSelectChange}
                >
                  {this.state.myCoords.map((option, index) => (
                    <Select.Option
                      key={index}
                      value={option.name}
                      onMouseEnter={() => this.handleOptionHover(option)}
                      onMouseLeave={this.handleOptionLeave}
                      style={{ cursor: "auto" }}
                      //position="relative"
                    >
                      <div>
                        <div>{option.name}</div>

                        {this.state.hoveredOption === option && (
                          <DeleteTwoTone
                            onMouseEnter={() => this.handlecheckHover()}
                            onMouseLeave={this.handlecheckLeave}
                            type="delete"
                            //onClick={() => this.deleteCoord()}
                            style={{
                              size: "large",
                              //marginLeft: "50px",
                              cursor: "pointer",
                              position: "absolute",
                              right: 10,
                              top: 10,
                            }}
                          />
                        )}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ) : null}

            {/* enable stamp coord here */}
            {/*
            {this.state.stamp_count ? (
              <div
                style={{
                  paddingLeft: "10.5%",
                  paddingTop: 15,
                  display: "flex",
                  width: "50%",
                }}
              >
                <Input
                  placeholder="name"
                  style={{ width: "40%", height: "72%", textAlign: "center" }}
                  value={this.state.stamp_name}
                  onChange={(data) => {
                    this.setState({ stamp_name: data.target.value });
                  }}
                ></Input>
                <Button onClick={() => this.saveCoord()}>Save</Button>
                <Select
                  placeholder="Select"
                  style={{
                    marginLeft: "10%",
                    textAlign: "center",
                    width: "70%",
                  }}
                  onChange={this.handleSelectChange}
                  //onSelect={this.handleSelectChange}
                >
                  {this.state.myCoords.map((option, index) => (
                    <Select.Option
                      key={index}
                      value={option.name}
                      onMouseEnter={() => this.handleOptionHover(option)}
                      onMouseLeave={this.handleOptionLeave}
                      style={{ cursor: "auto" }}
                    >
                      {option.name}
                      {this.state.hoveredOption === option && (
                        <DeleteTwoTone
                          onMouseEnter={() => this.handlecheckHover()}
                          onMouseLeave={this.handlecheckLeave}
                          type="delete"
                          //onClick={() => this.deleteCoord()}
                          style={{ marginLeft: "50px", cursor: "pointer" }}
                        />
                      )}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ) : null} */}

            <div className="pdf_controller">
              {this.state.transform.scale !== 1 && (
                <Button
                  icon={<CloseCircleOutlined />}
                  shape="circle"
                  onClick={() => this.pdfTransformer(1)}
                />
              )}
              <Button
                icon={<ZoomInOutlined />}
                shape="circle"
                onClick={() =>
                  this.pdfTransformer(this.state.transform.scale + 0.2)
                }
                type="primary"
              />
              <Button
                icon={<ZoomOutOutlined />}
                shape="circle"
                onClick={() =>
                  this.pdfTransformer(this.state.transform.scale - 0.2)
                }
                type="primary"
              />

              {/* <Button
                icon={<RotateLeftOutlined />}
                shape="circle"
                onClick={() =>
                  this.pdfTransformer(null, this.state.transform.rotate - 90)
                }
              />
              <Button
                icon={<RotateRightOutlined />}
                shape="circle"
                onClick={() =>
                  this.pdfTransformer(null, this.state.transform.rotate + 90)
                } */}
            </div>
            {!this.props.hide_add
              ? [
                  <div
                    key="1"
                    className="review_pdf_container_action"
                    onClick={() => {
                      let ind = this.state.rotate + 1;
                      if (ind > 3) {
                        ind = 0;
                      }
                      this.setState({ rotate: ind });
                    }}
                  >
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon="redo" />
                  </div>,
                  <div
                    key="2"
                    className="review_pdf_container_action"
                    onClick={() => {
                      let ind = this.state.rotate - 1;
                      if (ind < 0) {
                        ind = 3;
                      }
                      this.setState({ rotate: ind });
                    }}
                  >
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon="undo" />
                  </div>,
                  <div
                    key="3"
                    className="review_pdf_container_action"
                    onClick={() => {
                      let ind = this.state.scale - 20;
                      if (ind < 20) {
                        ind = 20;
                      }
                      this.setState({ scale: ind });
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      icon="search-minus"
                    />
                  </div>,
                  <div key="4" className="review_pdf_container_action">
                    <input
                      style={{ width: 60, textAlign: "center" }}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      value={this.state.scale}
                      onChange={(data) => {
                        let val = data.target.value * 1;
                        if (!isNaN(val) && val > 0 && val < 300) {
                          this.setState({ scale: val });
                        }
                      }}
                    />
                  </div>,
                  <div
                    key="5"
                    className="review_pdf_container_action"
                    onClick={() => {
                      let ind = this.state.scale + 20;
                      if (ind > 300) {
                        ind = 300;
                      }
                      this.setState({ scale: ind });
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      icon="search-plus"
                    />
                  </div>,
                ]
              : null}
          </div>
          {this.props.onClose ? (
            <div
              className="review_pdf_fixed_close"
              onClick={this.props.onClose}
            >
              <FontAwesomeIcon icon="times" size="3x" />
            </div>
          ) : null}
        </div>

        <div className="upload_steps_body" style={{ paddingTop: 30 }}>
          {this.state.visible && (
            <div className="opacity_overlay" onClick={this.infoHandler} />
          )}
          <div
            className="preview_info_overlay"
            style={{
              transform: `translateX(${this.state.visible ? "0" : "-100vw"})`,
            }}
          >
            <div className="preview_info_contents">
              <div>
                <span style={{ opacity: "0.7" }}>Status: </span>
                {this.props.docs?.status}

                {/* <button onClick={()=>console.log(this.state.docInfo)}>asd</button> */}
              </div>
              {this.props.docs?.status === "WAITING" &&
              this.props.actiontype === "signer" ? (
                <div className="step1_actions">
                  <button
                    className="btn "
                    bg_color="5"
                    onClick={() => {
                      this.props.funcs.doc_sign(this.props.docs?.docid);
                    }}
                  >
                    sign
                  </button>
                  <button
                    className="btn "
                    bg_color="6"
                    onClick={() => {
                      this.props.funcs.doc_reject(this.props.docs?.docid);
                    }}
                  >
                    reject
                  </button>
                </div>
              ) : this.props.docs?.status === "WAITING" &&
                this.props.actiontype === "approver" ? (
                <div className="step1_actions">
                  <div>
                    <span style={{ opacity: "0.7" }}>Status: </span>
                    {this.props.docs?.status}

                    {/* <button onClick={()=>console.log(this.state.docInfo)}>asd</button> */}
                  </div>
                  <button
                    className="btn "
                    bg_color="5"
                    onClick={() => {
                      this.props.funcs.doc_approve(this.props.docs?.docid);
                    }}
                  >
                    approve
                  </button>
                  <button
                    className="btn "
                    bg_color="6"
                    onClick={() => {
                      this.props.funcs.doc_reject(this.props.docs?.docid);
                    }}
                  >
                    reject
                  </button>
                </div>
              ) : this.props.docs?.status === "WAITING" &&
                this.props.actiontype === "copy" ? (
                <div className="preview_reminder">
                  {" "}
                  <InfoCircleOutlined />
                  You have no permission to sign , approve or reject this
                  document.
                </div>
              ) : (
                console.log("def")
              )}

              <div className="step1_user_detail" style={{ marginTop: 20 }}>
                <div className="step1_user_detail_title">
                  {Translate[ln].NewDoc.Preview.Sender}
                </div>
                <div className="step1_user_detail_body">
                  <div className="user_dropdown_name">
                    {this.props.senderx?.name}
                  </div>
                  <div className="user_dropdown_mobile">
                    {this.props.senderx?.msi}
                  </div>
                  <div className="user_dropdown_email" color="1">
                    {this.props.senderx?.email}
                  </div>
                </div>
              </div>

              {parts?.map((per, i) => {
                // const { name } = roles[per.role];
                return (
                  <div
                    key={i}
                    className="step1_user_detail"
                    style={{ marginTop: 20 }}
                  >
                    <div
                      className="step1_user_detail_title"
                      style={{
                        background: per.bg_color,
                        opacity:
                          this.props.rendertitle(i) == "Signed" ? "0.6" : "1",
                      }}
                    >
                      {/* {Translate[ln].NewDoc.Preview[name]} */}
                      {this.props.rendertitle(i)}
                      <div
                        className="step1_user_signed"
                        isSigned={per?.is_signed}
                      >
                        {this.props.rendertitle(i) == "Signed" ? null : (
                          <CheckSquareOutlined />
                        )}
                      </div>
                    </div>
                    <div className="step1_user_detail_body">
                      <div className="user_dropdown_name">{per?.name}</div>
                      <div className="user_dropdown_mobile">{per?.msisdn}</div>
                      <div className="user_dropdown_email" color="1">
                        {per.email}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div onClick={this.infoHandler} className="preview_close_x">
                X
              </div>
            </div>
          </div>

          <div
            className="review_pdf_container"
            style={{
              maxWidth: this.state.max.w === 0 ? "100%" : this.state.max.w,
            }}
          >
            <Document
              id="pdf-cont"
              file={this.props.file}
              onLoadSuccess={({ numPages }) => {
                this.setState({ numPages: numPages });
                if (this.props.onSuccess) {
                  this.props.onSuccess();
                }
              }}
              style={{ background: "red" }}
              className="my_pdf"
            >
              <Page
                height={1000}
                pageNumber={this.state.activepage}
                rotate={this.state.rotate * 90}
                scale={this.state.scale / 100}
              >
                <div className="boxes_wrapper">
                  <div
                    className="boxes_drag_area"
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {this.props.boxes?.map(
                      (per, i) =>
                        per.page === this.state.activepage && (
                          <Drag
                            dragstart={(ref) => {
                              this.mouseDown(ref, i);
                            }}
                            {...per}
                            key={i + "_" + per.drag_txt}
                            index={i}
                            disabled={per.uid !== this.props.user?.user_id}
                            locked={this.props.locked}
                            value={per.value}
                            updateHandler={(ref) => {
                              this.updateHandler(ref, i);
                            }}
                            lockInputs={lockInputs}
                          />
                        )
                    )}
                    <div className="drag_footer_box">Footer text</div>
                    <div
                      id="app_overlay"
                      onMouseMove={this.mouseMove}
                      onMouseUp={this.mouseUp}
                      ref={this.overlayRef}
                      onClick={() => console.log("mouse click")}
                    />
                  </div>
                </div>
              </Page>
            </Document>
            {/* <div id="app_overlay" /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default example;
