import React, { useState, useRef, useEffect } from "react";
import { axios, custom, Loading, reset, getStore, setStore } from "@/main";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Alert, Form, Input, notification, Modal } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import PasswordIndicator from "./PasswordIndicator";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Example(props) {
  const logo = "/logo.png";
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("info");
  const [valid, setValid] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [message, setMessage] = useState("Password should contain:");
  const [description, setDescription] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState({
    minChar: null,
    number: null,
    specialChar: null,
    capital: null,
  });

  const query = useQuery();
  const input1 = useRef();
  const input2 = useRef();

  const submit = () => {
    validate();
    if (!valid) return 0;
    let json = {
      email: query.get("email"),
      token: query.get("token"),
      password: password1,
    };
    setLoading(true);
    axios
      .post("/client/auth/renew", json)
      .then((e) => {
        reset();
        notification["success"]({
          message: "Амжилттай (fu)",
          description: "Бүртгэлтэй мэйлээ шалгана уу.",
        });

        // let red = custom.getCookie("redirect_url");
        // custom.setCookie("redirect_url","");
        // setStore("user",e.data.detail);
        // if(red && window.location.hash !== red){
        //   window.location.hash = red;
        // }else{
        window.location.hash = "#/login";
      })
      .catch((e) => {
        notification["error"]({
          message: "Алдаа гарлаа (fu)",
          description: e.data.message,
        });
        setLoading(false);
      });
    setLoading(false);
  };
  const pass1 = (e) => {
    let pass = e.target.value;
    if (pass.length > 25) {
      return;
    } else {
      // let minChar =
      setPassword1(pass);
      validate();
      setPasswordValidity({
        minChar: pass.length >= 8,
        number: /[0-9]/.test(pass),
        capital: /[A-Z]/.test(pass),
        specialChar: /[!@#$%^&*]/.test(pass),
      });
    }
  };
  const pass2 = (e) => {
    setPassword2(e.target.value);
    validate();
  };
  useEffect(() => {
    validate();
  });
  const validate = () => {
    password1?.length > 0 && password2?.length > 0 && password1 === password2
      ? setValid(true)
      : setValid(false);
  };
  const passwordRule = [
    {
      required: true,
      message: "Password can not be empty!",
    },
    {
      min: 8,
      message: "Password cannot be less than 8 characters",
    },
    {
      max: 25,
      message: "Password cannot be greater than 25 characters",
    },
  ];
  return (
    <div className="login_container">
      <div className="login">
        <Loading loading={loading} />
        <div style={{ textAlign: "center" }}>
          <img className="login_image" src={logo} alt="logo" />
        </div>

        <Form className="login_form" autoComplete="off" onFinish={submit}>
          <div className="login_title">PASSWORD RECOVERY</div>
          <Form.Item name="password" rules={passwordRule}>
            <PasswordInput
              ref={input1}
              inputProps={{ maxLength: 25 }}
              style={{ marginTop: 10, width: "100%" }}
              onChange={pass1}
            />
          </Form.Item>
          {password1.length > 7 && (
            <Form.Item name="confirm" rules={passwordRule}>
              <PasswordInput
                ref={input2}
                inputProps={{ maxLength: 25 }}
                onChange={pass2}
                style={{ marginTop: 10, width: "100%" }}
              />
            </Form.Item>
          )}
          {password2.length > 7 && (
            <button className="login_button" onClick={onsubmit}>
              Continue
            </button>
          )}
          {!valid ? (
            <PasswordIndicator validity={passwordValidity} />
          ) : (
            <Alert
              style={{ marginTop: 10 }}
              message="Password approved."
              description="Please submit your new password."
              type="success"
              showIcon
            />
          )}
        </Form>

        <div className="login_footer">
          VSign.mn © 2021, Inc. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default Example;
