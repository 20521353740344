import React, { useEffect, useState } from "react";
import { axios, DateTime, getStore } from "@/main/";
import {
  Divider,
  Drawer,
  Upload,
  Steps,
  Input,
  notification,
  Button,
  Dropdown,
} from "antd";
import {
  DeleteOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
  EllipsisOutlined,
  BarsOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { CheckRole, DragDrop, SelectContacts } from "@/comps/upload/";
import { PreviewRoot } from "@/comps";
import moment from "moment";
import TemplateRoot from "./TemplateRoot";
import Menu from "rc-menu/lib/Menu";

import { useDispatch, useSelector } from "react-redux";
import {
  partsReset,
  partsAdd,
  roleChange,
  partsRemove,
} from "../../store/actions";
import Participant from "./Participant";
import Template from "./Template";

import ShortUniqueId from "short-unique-id";
import { Translate } from "../../translate";
const uid = new ShortUniqueId({ length: 5 });

const Index = (props) => {
  const [templates, setTemplates] = useState(null);
  const [upSteps, setUpSteps] = useState(0);
  const [fileInfo, setFileInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const parts = useSelector((state) => state.draft.parts);

  const [stamps, setStamps] = useState([]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState("Draft name");

  let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

  const user = getStore("user");

  const getTemplates = () => {
    setLoading(true);

    axios
      .post("client/api/doc/template/list", {})
      .then(({ data }) => {
        setTemplates(data.list);
      })
      .catch((err) => {
        notification.error({ message: err.data.message, duration: 4 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteTemplate = (id) => {
    setLoading(true);

    axios
      .post("client/api/doc/template/delete", { id: id })
      .then((res) => {
        getTemplates();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const startTemplate = () => {
    window.location.hash = "#/templates/2/0/0";
  };

  const makeBox = (per) => {
    let box = document.createElement("div");
    let inside = document.createElement("div");
    box.style.height = per.height + "px";
    box.style.width = per.width + "px";
    box.style.background = per.color.replace("1)", " 0.7)");
    box.style.border = "1px solid " + per.color;
    box.style.left = per.x1 + "px";
    box.style.bottom = per.y1 + "px";
    box.style.position = "absolute";
    box.className = "drop_box";
    inside.className = "drop_box_name";
    inside.style.width = per.boxwidth + "px";
    inside.style.overflowWrap = "break-word";
    inside.innerHTML = per.value ? per.value : per.drag_txt;
    box.appendChild(inside);
    if (per.unique_key === "signature") {
      let inside_name = document.createElement("div");
      inside_name.className = "drop_box_sign";
      inside_name.innerHTML = per.username;
      box.appendChild(inside_name);
    }
    return page === per.page ? box : null;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (upSteps > 2) {
      let container = document.querySelector(
        ".document_watermark_container_dropable"
      );
      container.innerHTML = "";
      stamps.map(
        (per, i) => page === per.page && container.appendChild(makeBox(per))
      );
    }
  }, [page]);

  const closeDrawer = () => {
    window.location.hash = "#/templates/0/0/0";
  };

  const uploadFile = (file) => {
    setFileInfo(file);
    setUpSteps(upSteps + 1);
  };

  const addPart = () => {
    const colors = getStore("color");

    if (parts.length < 10) {
      let id = uid();
      dispatch(
        partsAdd({
          name: "Signer ",
          role: "SIGN",
          key: id,
          msisdn: "",
          email: "",
          user_id: id,
          color: colors[parts.length],
          bg_color: colors[parts.length],
          actionrole: "signer",
          can_sign: true,
          move_sign: false,
          coords: [],
          disable: false,
        })
      );
    } else {
      notification.error({ message: "Max limit is 10.", duration: 4 });
    }
  };

  const changeName = (val, idx) => {
    let tmp = [...parts];

    tmp[idx].name = val;

    // setParts(tmp);
  };

  // console.log(user);

  const renderSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="step0_container relafull flex_center">
            <div className="upload_section flex_center">
              <div>
                <img src="/file-add.svg" alt="" />
              </div>
              <div className="upload_section_title">Drop Document here</div>
              <div className="upload_section_body">or Click here</div>
              <div className="second_circle" />
              <Upload.Dragger
                accept="application/pdf"
                multiple={false}
                className="upload_dragger absfull"
                beforeUpload={uploadFile}
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="file_container">
            <div className="file_container_header">
              <div className="file_info_box">
                <div className="file_info_header">
                  {" "}
                  {Translate[ln].Template.DraftInfo}
                </div>
                <div className="double_info">
                  <text> {Translate[ln].Template.DraftName}</text>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="file_info_box">
                <div className="file_info_header">
                  {Translate[ln].Template.SenderInfo}{" "}
                </div>
                <div className="double_info">
                  <text>{Translate[ln].Template.Name}</text>
                  <text> {user?.kdss_name}</text>
                </div>
                <div className="double_info">
                  <text>{Translate[ln].Template.MSISDN}</text>
                  <text>{user?.kdss_msisdn}</text>
                </div>
                <div className="double_info">
                  <text>{Translate[ln].Template.Email}</text>
                  <text>{user?.kdss_email}</text>
                </div>
              </div>
              <div className="file_info_box">
                <div className="file_info_header">
                  {" "}
                  {Translate[ln].Template.FileInfo}{" "}
                </div>
                <div className="double_info">
                  <text> {Translate[ln].Template.FileName}</text>
                  <text> {fileInfo?.name}</text>
                </div>
                <div className="double_info">
                  <text> {Translate[ln].Template.FileSize}</text>
                  <text>{`${fileInfo?.size / 1000} kB`}</text>
                </div>

                <div className="double_info">
                  <text>{Translate[ln].Template.ModDate}</text>
                  <text>
                    {" "}
                    <DateTime data={fileInfo?.lastModifiedDate?.toString()} />
                  </text>
                </div>
              </div>

              <div className="btn button_flex" bg_color="5" onClick={addPart}>
                {Translate[ln].Template.AddParticipants}
              </div>
            </div>
            <div className="template_participants">
              <div style={{ fontWeight: "600", fontSize: "24px" }}>{`${
                parts.length
              } ${parts.length === 1 ? "Participant" : "Participants"}`}</div>
              {parts?.map((item, idx) => (
                <Participant
                  index={idx}
                  key={item.key}
                  onChange={(val) => changeName(val, idx)}
                />
              ))}
            </div>
          </div>
        );

      case 2:
        return (
          <DragDrop
            file={fileInfo}
            parts={parts}
            setStamps={(stamps) => {
              setStamps(stamps);
            }}
            stamps={stamps}
            hideTag={true}
          />
        );
      default:
        return (
          <div className="step1_container relafull">
            <div className="step1_detail">
              <div className="step1_user_detail" style={{ marginTop: 20 }}>
                <div className="step1_user_detail_title">
                  {Translate[ln].NewDoc.Preview.Sender}{" "}
                </div>
                <div className="step1_user_detail_body">
                  <div className="user_dropdown_name">{user.kdss_name}</div>
                  <div className="user_dropdown_mobile">{user.kdss_msisdn}</div>
                  <div className="user_dropdown_email" color="1">
                    {user.kdss_email}
                  </div>
                </div>
              </div>
              <div className="parts_scroll_area">
                {parts?.map((per, i) => {
                  return (
                    <div
                      key={i}
                      className="step1_user_detail"
                      style={{ marginTop: 20, overflow: "hidden" }}
                    >
                      <div
                        className="step1_user_detail_body"
                        style={{ background: per.bg_color }}
                      >
                        <div className="user_dropdown_name">{per.name}</div>
                        <div className="user_dropdown_mobile">{per.role}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="step3_body">
              <div className="absfull">
                <PreviewRoot
                  file={fileInfo}
                  hide_add={1}
                  onSuccess={() => {
                    let element = document.createElement("div");
                    let dropable = document.createElement("div");
                    dropable.className =
                      "document_watermark_container_dropable";
                    element.className = "document_watermark_container";

                    stamps.map(
                      (per, i) =>
                        page === per.page && dropable.appendChild(makeBox(per))
                    );

                    element.appendChild(dropable);

                    document
                      .querySelector(".react-pdf__Page")
                      .appendChild(element);
                  }}
                  pageChange={(page) => setPage(page)}
                />
              </div>
            </div>
          </div>
        );
    }
  };

  const steps = [
    {
      title: "File upload",
      key: "FileUpload",
    },
    {
      title: "Set participants",
      key: "SetPart",
    },
    {
      title: "Add stamps",
      key: "AddStamps",
    },
    {
      title: "Preview",
      key: "Preview",
    },
  ];

  const clearStates = () => {
    setUpSteps(0);
    dispatch(partsReset());
    setStamps([]);
    setPage(1);
    setName("Draft name");
  };

  const createTemplate = () => {
    setLoading(true);
    console.log("check here", parts);
    //console.log('check here',parts);

    let body = {
      name: name,
      stamps: JSON.stringify(stamps),
      owner_id: user.id,
      signers: JSON.stringify(parts),
      signers_count: parts.length,
    };

    let file = new FormData();
    file.append("file", fileInfo);

    axios
      .post("client/api/doc/template/file/upload", file)
      .then((res) => {
        axios
          .post("client/api/doc/template/create", {
            ...body,
            doc_id: res.data?.doc_id,
          })
          .then(({ data }) => {
            notification.success({ message: data.message, duration: 3 });
            closeDrawer();
            getTemplates();
            clearStates();
          })
          .catch((err) => {
            notification.error({ message: err.data.message, duration: 4 });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        notification.error({ message: err.data.message, duration: 4 });
      });
  };

  const backhandler = () => {
    setUpSteps(upSteps - 1);
  };

  const upHandler = () => {
    if (upSteps === 3) {
      createTemplate();
    }
    upSteps < 3 && setUpSteps(upSteps + 1);
  };

  const stepHandler = (step) => {
    if (step < upSteps) setUpSteps(step);
  };

  const renderTitle = () => {
    return (
      <>
        {upSteps > 0 && (
          <Button onClick={backhandler}>{Translate[ln].Template.Prev}</Button>
        )}
        <Steps current={upSteps} onChange={(e) => stepHandler(e)} responsive>
          {steps.map((item) => (
            <Steps.Step
              key={item.title}
              title={Translate[ln].Template[item.key]}
            />
          ))}
        </Steps>
        {upSteps !== 0 && (
          <Button onClick={upHandler}>
            {upSteps == 3
              ? Translate[ln].Template.Save
              : Translate[ln].Template.Next}
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="templates">
      <TemplateRoot params={props.match?.params} getList={getTemplates} />
      {loading && (
        <div className="loader">
          <LoadingOutlined />
        </div>
      )}
      <Drawer
        className="custom_drawer"
        visible={props.match.params.popfilter === "2"}
        onClose={closeDrawer}
        placement="bottom"
        height="100%"
        title={<div className="drawer_title">{renderTitle()}</div>}
      >
        {renderSteps(upSteps)}
      </Drawer>
      <Divider>{Translate[ln]?.Drafts?.AllDraft}</Divider>
      <div className="templates_map">
        <div className="template">
          <div className="template_create" onClick={startTemplate}>
            +
          </div>
        </div>
        {templates?.map((e, idx) => (
          <Template data={e} key={idx} deleteAction={deleteTemplate} />
        ))}
      </div>
    </div>
  );
};

export default Index;
