import React from "react";
import { Input } from "antd";
const { TextArea } = Input;

const globalStyle = {
  fontSize: "10px",
  fontFamily: "'Roboto'",
  fontWeight: "light",
};

class example extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      maxChar: 1,
      typeValue: "",
    };
    this.inputRef = React.createRef();
  }

  onMouseDown = (e) => {
    console.log("worked on mouse down");
    let rect = e.target.getBoundingClientRect();
    let shiftX = e.offsetX;
    let shiftY = e.offsetY;

    if (rect.width - shiftX > 8 && rect.height - shiftY > 8) {
      this.ref.setAttribute("shiftx", shiftX);
      this.ref.setAttribute("shifty", shiftY);
      // (!this.props.disabled || !this.props.locked) &&
      //   this.props.dragstart(this.ref);
      console.log("onmousedown", this.ref);

      if (this.props.disabled) return;
      if (this.props.locked) return;

      this.props.dragstart(this.ref);
    }
  };

  componentDidMount() {
    this.ref = document.querySelector(
      "#" + this.props.unique_key + this.props.index
    );
    this.ref.addEventListener("mousedown", this.onMouseDown);

    const input = document.querySelector(
      `#input_${this.props.unique_key}_${this.props.index}`
    );

    if (input) {
      this.setState({ maxChar: input.value.length + 1 });
    }

    const handlePasteAnywhere = (event) => {
      const text = event.clipboardData.getData("text");

      if (
        event.target.id === `input_${this.props.unique_key}_${this.props.index}`
      ) {
        this.setState({ maxChar: text.length + 1, typeValue: text });
        this.props.updateHandler(event);
      }
    };

    window.addEventListener("paste", handlePasteAnywhere);

    return () => {
      window.removeEventListener("paste", handlePasteAnywhere);
    };
  }

  onInputChange = (val) => {
    this.inputRef.current.innerHTML = val;
    const w = this.inputRef.current.getBoundingClientRect().width;
    const propWidth = this.props.style.width;

    console.log(this.props, w);
    if (propWidth - w < 12) this.setState({ maxChar: val.length });
    else {
      this.setState({ typeValue: val, maxChar: 1 + val.length });
    }
  };

  render() {
    let {
      left,
      top,
      unique_key,
      style,
      color,
      drag_txt,
      disabled,
      index,
      locked,
      updateHandler,
      url,
      value,
      max,
      lockInputs,
    } = this.props;
    // For custom text field

    console.log("unique key", unique_key);
    //console.log("this ref", this.ref);
    if (unique_key === "shortText") {
      return (
        <>
          <div
            id={unique_key + index}
            className={`drag ${locked ? "" : disabled ? "drag_disabled" : ""}`}
            style={{
              display: "flex",
              top: top,
              left: left,
              width: style.width,
              height: "auto",
              backgroundColor: color.replace("1)", "0.7)"),
              border: `1px solid ${color}`,
            }}
          >
            {disabled && (
              <div className="drag_box_overlay" style={{ zIndex: 4 }} />
            )}
            <div
              ref={this.inputRef}
              style={{
                position: "absolute",
                height: "100%",
                // width: this.state.w,
                opacity: 0,
                left: 0,
                top: 0,
                ...globalStyle,
              }}
            />
            <Input
              value={this.state.value}
              readOnly={lockInputs}
              id={`input_${unique_key}_${index}`}
              rows={1}
              maxLength={this.state.maxChar}
              onChange={(e) => {
                updateHandler(e);
                this.onInputChange(e.target.value);
                console.log("changed!");
              }}
              style={{
                ...globalStyle,
                padding: 0,
                resize: "both !important",
                width: "100%",
                height: "auto",
                blockSize: "fit-content",
                backgroundColor: "transparent",
                overflow: "auto !important",
                zIndex: 5,
              }}
              placeholder="Enter custom text here."
              autoSize={{ minRows: 1, minCols: 12 }}
              defaultValue={value}
              width="100%"
            />
          </div>
        </>
      );
    }

    // For Web Link
    if (unique_key === "link") {
      return (
        <>
          <div
            id={unique_key + index}
            className={`drag ${locked ? "" : disabled ? "drag_disabled" : ""}`}
            style={{
              display: "flex",
              top: top,
              left: left,
              width: "auto",
              height: "auto",
              backgroundColor: color.replace("1)", "0.7)"),
              border: `1px solid ${color}`,
            }}
          >
            {disabled && <div className="drag_box_overlay" />}
            <div
              ref={this.inputRef}
              style={{
                position: "absolute",
                height: "100%",
                opacity: 0,
                left: 0,
                top: 0,
                ...globalStyle,
              }}
            />
            <Input
              value={this.state.value}
              readOnly={lockInputs}
              id={`input_${unique_key}_${index}`}
              maxLength={this.state.maxChar}
              onChange={(e) => {
                updateHandler(e);
                this.onInputChange(e.target.value);
                console.log("changed!");
              }}
              style={{
                ...globalStyle,
                padding: 0,
                resize: "both",
                width: "fit-content",
                height: "auto",
                blockSize: "fit-content",
                backgroundColor: "transparent",
                overflow: "auto !important",
                zIndex: 5,
              }}
              placeholder="Enter link here"
              autoSize={{ minRows: 1, minCols: 12 }}
              defaultValue={value}
            />
          </div>
        </>
      );
    }

    if (unique_key === "mediumText") {
      return (
        <>
          <div
            id={unique_key + index}
            className={`drag ${locked ? "" : disabled ? "drag_disabled" : ""}`}
            style={{
              display: "flex",
              top: top,
              left: left,
              width: style.width,
              height: "auto",
              backgroundColor: color.replace("1)", "0.7)"),
              border: `1px solid ${color}`,
            }}
          >
            {disabled && <div className="drag_box_overlay" />}
            <div
              ref={this.inputRef}
              style={{
                position: "absolute",
                height: "100%",
                opacity: 0,
                left: 0,
                top: 0,
                ...globalStyle,
              }}
            />
            <Input
              value={this.state.value}
              readOnly={lockInputs}
              id={`input_${unique_key}_${index}`}
              rows={1}
              maxLength={this.state.maxChar}
              onChange={(e) => {
                updateHandler(e);
                this.onInputChange(e.target.value);
                console.log("changed!");
              }}
              style={{
                ...globalStyle,
                padding: 0,
                resize: "both !important",
                width: "100%",
                height: "auto",
                blockSize: "fit-content",
                backgroundColor: "transparent",
                overflow: "auto !important",
                zIndex: 5,
              }}
              placeholder="Enter custom text here."
              autoSize={{ minRows: 1, minCols: 12 }}
              onBlur={this.onBlur}
              defaultValue={value}
            />
          </div>
        </>
      );
    }

    if (unique_key === "largeText") {
      return (
        <>
          <div
            id={unique_key + index}
            className={`drag ${locked ? "" : disabled ? "drag_disabled" : ""}`}
            style={{
              display: "flex",
              top: top,
              left: left,
              width: style.width,
              height: "auto",
              backgroundColor: color.replace("1)", "0.7)"),
              border: `1px solid ${color}`,
            }}
          >
            {disabled && <div className="drag_box_overlay" />}
            <div
              ref={this.inputRef}
              style={{
                position: "absolute",
                height: "100%",
                opacity: 0,
                left: 0,
                top: 0,
                ...globalStyle,
              }}
            />
            <Input
              value={this.state.value}
              readOnly={lockInputs}
              id={`input_${unique_key}_${index}`}
              maxLength={this.state.maxChar}
              onChange={(e) => {
                updateHandler(e);
                this.onInputChange(e.target.value);
                console.log("changed!");
              }}
              style={{
                ...globalStyle,
                padding: 0,
                resize: "both !important",
                width: "100%",
                height: "auto",
                blockSize: "fit-content",
                backgroundColor: "transparent",
                overflow: "auto !important",
                zIndex: 5,
              }}
              placeholder="Enter custom text here."
              autoSize={{ minRows: 1, minCols: 12 }}
              onBlur={this.onBlur}
              defaultValue={value}
            />
          </div>
        </>
      );
    }

    // For other fields than custom text
    return (
      <div
        id={unique_key + index}
        className={`drag ${locked ? "" : disabled ? "drag_disabled" : ""}`}
        style={{
          top: top,
          left: left,
          width: style.width,
          height: style.height,
          backgroundColor: url ? "#fffff" : color.replace("1)", "0.7)"),
          border: `1px solid ${color}`,
          backgroundImage: `url(${url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPositionX: "right",
          ...globalStyle,
        }}
      >
        <div className="drag_box_overlay" />
        {this.props.body ? (
          this.props.body
        ) : url ? (
          <span className="yourSignature">{drag_txt}</span>
        ) : (
          <span>{drag_txt}</span>
        )}
        {this.props.username && (
          <span className="stamp_username">{this.props.username}</span>
        )}
      </div>
    );
  }
}

export default example;
