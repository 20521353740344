import React from "react";

import { Input, Select, Slider } from "antd";
import { PreviewRoot } from "@/comps";
import { getStore, custom, setStore } from "@/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import { boxAdd } from "../../store/actions";
import { axios } from "../../main/axios";
import { Translate } from "../../translate";

class example extends React.Component {
  constructor(props) {
    super(props);
    this.fields = getStore("fields");
    this.customFields = getStore("customFields");
    this.roles = getStore("roles");
    this.parts = this.props.wholeState.draft.parts;
    this.draft = this.props.wholeState.draft;
  }
  crt = {};
  state = {
    reload: 0,
    saveup: 0,
    selected: 0,
    boxes: this.props.stamps,
    lastSelected: null,
    selectedUser: this.props.parts[0],
    allUserx: this.props.parts,
    active_i: undefined,
    txt: "",
    user_stamps: [],
  };
  reload = () => {
    this.setState({ reload: this.state.reload + 1 });
  };

  page = 1;

  renderTools = () => {
    let active = this.parts[this.state.selected];
    let coords = [];
    let container = document.querySelector(".document_watermark_container");
    let droppable = document.createElement("div");
    droppable.className = "document_watermark_container_dropable";
    container.innerHTML = "";
    container.appendChild(droppable);
    this.props.parts.map((per, i) => {
      per.coords.map((per1, i1) => {
        per1.part_index = i;
        per1.coords_index = i1;
        per1.type = "old";
        per1.footer = per.name || per.msisdn || per.email;
        coords.push(per1);
      });
    });
    coords.map((per, i) => {
      if (this.page === per.page) {
        let box = this.funcs.make_box(per, 1, active);
        container.appendChild(box);
      }
    });
    this.reload();
  };

  onDragStart = (event, per) => {
    console.log("drag start");
    //xwconsole.log("coords here", coords);

    this.setState({ lastSelected: per });
    let user = this.state.selectedUser;
    let crt = document.createElement("div");
    crt.setAttribute("class", "drag");
    crt.innerHTML = `<span class="${per.url ? "yourSignature" : ""}">${
      per.drag_txt
    } aa </span> ${
      per.unique_key === "signature"
        ? `<span class="stamp_username">${user.name}</span>`
        : ""
    }`;
    crt.style.backgroundColor = user.color;
    crt.style.height = per.style?.height + "px";
    crt.style.width = per.style?.width + "px";
    crt.style.position = "absolute";
    crt.style.bottom = `-${per.style?.height}px`;
    crt.style.left = "0";
    crt.style.zIndex = "-1";
    if (per.url) {
      crt.style.backgroundColor = "#ffffff";
      crt.style.backgroundImage = "url('" + per.url + "')";
      crt.style.backgroundRepeat = "no-repeat";
      crt.style.backgroundSize = "contain";
      crt.style.backgroundPositionX = "right";
    }

    event.target.appendChild(crt);
    event.dataTransfer.setDragImage(crt, 0, per.style?.height / 2);

    window.setTimeout(function () {
      crt.parentNode.removeChild(crt);
    }, 0);
  };

  onDragEnd = (event) => {
    console.log("drag end");

    let container = document.querySelector(
      ".document_watermark_container_dropable"
    );
    container = container.getBoundingClientRect();

    let x = event.clientX,
      y = event.clientY,
      px = container.left,
      px1 = container.left + container.width,
      py = container.top,
      py1 = container.top + container.height;

    if (x >= px && x <= px1 && y >= py && y <= py1) {
      let { parts } = this.props;
      let item = { ...this.state.lastSelected };
      let { selected } = this.state;

      item.type = item.unique_key;
      item.left = event.pageX - container.left;
      item.top = event.pageY - container.top;
      item.color = this.state.selectedUser.color;
      item.uid = this.state.selectedUser.user_id;
      item.msisdn = this.state.selectedUser.msisdn;
      item.x1 = item.left;
      item.y1 = container.height - item.top - item.style.height;
      item.width = item.style.width;
      item.height = item.style.height;
      item.part_index = this.state.selected;

      if (
        ["yourStamp", "yourSignature", "yourInitials"].includes(item.unique_key)
      )
        item.value = item.imageid;
      else item.value = "";

      let overlapped = [];
      this.state.boxes.forEach((el, idx) => {
        let active = {
          x1: item.x1,
          y1: item.y1,
          x2: item.x1 + item.width,
          y2: item.y1 + item.height,
        };
        let elem = {
          x1: el.x1,
          y1: el.y1,
          x2: el.x1 + el.width,
          y2: el.y1 + el.height,
        };
        el.page === this.page &&
          overlapped.push(custom.intersect(active, elem));
      });
      if (!overlapped.includes(true)) {
        let tmp = [...this.state.boxes, item];
        this.setState({ boxes: tmp });
        this.setState({ active_i: this.state.boxes.length });
        if (this.state.lastSelected.unique_key === "signature") {
          item.username = this.state.selectedUser.name;
          parts[selected].move_sign = true;
        }
      }
    }
  };
  // check here
  setBoxes = (boxes) => {
    console.log("setBoxes in Dragrop", boxes);
    this.props.setStamps(boxes);
    this.setState({ boxes });
    let temp = this.state.saveup + 1;
    this.setState({ saveup: temp });
  };

  setActive = (id) => {
    this.setState({ active_i: id });
  };

  renderIcon = (type) => {
    switch (type) {
      case "signer":
        return <EditOutlined />;
      case "approver":
        return <UserOutlined />;
      default:
        return <MailOutlined />;
    }
  };

  renderCustomType = (unique_key) => {
    const tmp = {
      shortText: "Short Text",
      link: "Web Link",
      mediumText: "Medium Text",
      largeText: "Long Text",
    };

    return tmp[unique_key] || "Custom field";
  };

  renderCustomField = (unique_key) => {
    switch (unique_key) {
      case "text":
        return <Input.TextArea />;
      default:
        return unique_key;
    }
  };

  deleteStamp = (id) => {
    let tmp = [...this.state.boxes];
    tmp.splice(id, 1);
    this.setBoxes(tmp);
    this.setState({ active_i: undefined });

    let idx = this.state.selected;
    let item = this.state.boxes[id];

    if (item.unique_key === "signature") {
      this.props.parts[idx].move_sign = false;
      this.renderTools();
    }
  };

  onChange = (e) => {
    console.log("this.context");
    console.log(this.context);
    console.log(this.state.boxes[this.state.active_i]);
    const tempName = "tmp_" + this.state.boxes[this.state.active_i].key;
    this.setState({
      [tempName]: e.target.value,
    });
    setStore({
      txt: e.target.value,
    });
    console.log("this.state.lastSelected");
    console.log(this.state.lastSelected);
    setTimeout(() => {
      this.renderTools();
    });
  };

  getUserStamps = async (id) => {
    const types = {
      eseal: "yourStamp",
      signature: "yourSignature",
      initials: "yourInitials",
    };

    const sizes = {
      eseal: {
        height: 150,
        width: 150,
      },
      signature: {
        height: 75,
        width: 150,
      },
      initials: {
        height: 75,
        width: 75,
      },
    };

    const res = await axios.get(`/client/api/user/${id}`);

    const { data } = res.data;

    const { images } = data[1];

    const base = {
      svgurl: true,
      icon: "stamp",
    };

    const arr = images?.map((e) => ({
      ...base,
      ...e,
      drag_txt: e.shortname,
      drag_desc: e.description,
      unique_key: types[e.imagetype],
      style: sizes[e.imagetype],
    }));

    console.log("arrays ", arr);

    this.setState({ user_stamps: arr });
  };

  componentDidUpdate(props, state) {
    if (state.selected !== this.state.selected) {
      if (!this.props.hideTag) {
        const { parts } = props;
        const user = parts[this.state.selected];

        this.getUserStamps(user.kdss_user);
      }
    }
  }

  componentDidMount() {
    if (this.props?.parts) {
      if (!this.props.hideTag) {
        const { parts } = this.props;
        const user = parts[0];

        this.getUserStamps(user.kdss_user);
      }
    }
  }

  render() {
    let { file, parts } = this.props;
    let { selected } = this.state;
    let a = parts[selected];
    let { bg_color } = a;
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

    // Slider
    function onChange(value) {
      console.log("onChange: ", value);
    }

    function onAfterChange(value) {
      console.log("onAfterChange: ", value);
    }
    // Slider

    return (
      <div className="draggable_container">
        <div
          style={{
            height: "calc(100% - 43px)",
            overflowY: "auto",
            backgroundColor: "white",
          }}
        >
          <div className="draggable_fields_container">
            <div className="drag_field_title">
              {Translate[ln].NewDoc.Customizations.Placeholder}
            </div>
            <div style={{ padding: "0 20px", paddingTop: 10 }}>
              <Select
                value={this.state.selected}
                onChange={(data) => {
                  this.setState({
                    selected: data,
                    selectedUser: this.props.parts[data],
                  });
                  setTimeout(() => {
                    this.renderTools();
                  });
                }}
                style={{ width: "100%" }}
              >
                {parts.map((per, i) => (
                  <Select.Option
                    key={i}
                    value={i}
                    style={{ background: per.color.replace(", 1)", ", 0.6)") }}
                  >
                    {this.renderIcon(per.actionrole)}
                    <FontAwesomeIcon icon={this.roles[per.role]?.icon} />
                    &nbsp;{per.name || per.msisdn || per.email}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="drag_field_title">
              {Translate[ln].NewDoc.Customizations.StandartFields}
            </div>
            <div className="seperator_field" style={{ display: "block" }} />
            {this.fields.map((per, i) => {
              let style = {};
              if (
                (!a.can_sign || a.move_sign) &&
                per.unique_key === "signature"
              ) {
                return false;
              }
              return (
                <div
                  draggable
                  style={style}
                  key={per.key}
                  custom_type={per.unique_key}
                  className="drag_field_container"
                  onDragStart={(event) => {
                    this.onDragStart(event, per);
                  }}
                  onDragEnd={this.onDragEnd}
                >
                  <div className="drag_field_icon">
                    <div
                      className="drag_field_icon_box"
                      style={{ background: bg_color }}
                    >
                      <FontAwesomeIcon icon={per.icon} />
                    </div>
                  </div>
                  <div className="drag_field_name">
                    {Translate[ln].NewDoc.Customizations.Stands[i]}
                  </div>
                </div>
              );
            })}
            <div
              className="drag_field_title"
              onClick={() => console.log(this.customFields)}
            >
              {Translate[ln].NewDoc.Customizations.CustomFields}
            </div>
            <div className="seperator_field" style={{ display: "block" }} />
            {this.customFields?.map(
              (per, i) =>
                !per.svgurl && (
                  <div
                    draggable
                    key={per.key}
                    custom_type={per.unique_key}
                    className="drag_field_container"
                    onDragStart={(event) => {
                      this.onDragStart(event, per);
                    }}
                    onDragEnd={this.onDragEnd}
                    onClick={() => console.log(per)}
                  >
                    <div className="drag_field_icon">
                      <div
                        className="drag_field_icon_box"
                        style={{ background: bg_color }}
                      >
                        <FontAwesomeIcon icon={per.icon} />
                      </div>
                    </div>
                    <div className="drag_field_name">
                      {/* {this.renderCustomType(per.unique_key)} */}
                      {Translate[ln].NewDoc.Customizations.Customs[i]}
                      <div className="characters">
                        {" "}
                        ~ {per.max?.char || 0} char
                      </div>
                    </div>
                  </div>
                )
            )}

            {!this.props.hideTag && (
              <div className="drag_field_title">
                {Translate[ln].NewDoc.Customizations.Userimgs}
                <div className="seperator_field" style={{ display: "block" }} />
              </div>
            )}
            <div>
              <div>
                {" "}
                {this.state.user_stamps?.map((per, idx) => {
                  //console.log("per", per);
                  return (
                    <div
                      style={{
                        border: "1px solid #eee",
                        margin: "8px 20px",
                        display: "flex",
                        padding: "15px 10px",
                        alignItems: "center",
                      }}
                      draggable
                      key={per.imageid}
                      // custom_type={per.imageid}
                      className="drag_sign_stamp"
                      onDragStart={(event) => {
                        this.onDragStart(event, per);
                      }}
                      onDragEnd={this.onDragEnd}
                    >
                      <img
                        src={`/${per.imagetype}.png`}
                        style={{ height: "25px" }}
                      />
                      <div style={{ padding: "0 10px" }}>
                        <div style={{ fontWeight: "600" }}>{per.shortname}</div>
                        <div style={{ fontSize: "11px" }}>
                          {per.description}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {this.state.active_i !== undefined && (
              <div
                className="last_selected"
                style={{
                  background: this.state.boxes[this.state.active_i]?.color,
                }}
              >
                <div className="last_selected_title">Last selected :</div>
                <div className="last_selected_container">
                  <FontAwesomeIcon
                    icon={["fas", this.state.boxes[this.state.active_i]?.icon]}
                  />{" "}
                  <span>{this.state.boxes[this.state.active_i]?.drag_txt}</span>
                  <div
                    className="last_selected_delete"
                    onClick={() => this.deleteStamp(this.state.active_i)}
                  >
                    <FontAwesomeIcon icon={["fas", "trash"]} />
                  </div>
                </div>
                {/* {
                this.state.boxes[this.state.active_i]?.drag_txt === 'Text'?
                  <Input.Group>
                    {/* <Slider
                      style={{backgroundColor: 'white !important'}}
                      defaultValue={30}
                      onChange={onChange}
                      onAfterChange={onAfterChange}
                    /> */}
                {/* <Input.TextArea
                      rows={5}
                      placeholder="Please enter your text here."
                      showCount maxLength={100} onChange={this.onChange}
                    />
                    <Input addonBefore="Height:" defaultValue="71" />
                    <Input addonBefore="Width:" defaultValue="140" />
                  </Input.Group>
                : null
              } */}
              </div>
            )}
            {/* <div
            className="remove_drag_drop"
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={(e) => {
              let { coords_index } = this.crt;
              let { selected } = this.state;
              let { parts } = this.props;
              if (coords_index >= 0 && parts[selected].coords[coords_index]) {
                if (this.crt.unique_key === "signature") {
                  parts[selected].move_sign = false;
                }
                delete parts[selected].coords[coords_index];
                this.renderTools();
              }
            }}
            >
            <FontAwesomeIcon icon="trash" />
            &nbsp; Remove
          </div> */}
          </div>
        </div>
        <div className="draggable_pdf_container">
          <div className="absfull">
            <PreviewRoot
              hide_add={1}
              file={file}
              pageChange={(page) => {
                this.page = page;
                this.renderTools();
              }}
              onSuccess={() => {
                let element = document.createElement("div");
                let dropable = document.createElement("div");
                dropable.className = "document_watermark_container_dropable";
                element.className = "document_watermark_container";
                element.appendChild(dropable);
                document.querySelector(".react-pdf__Page").appendChild(element);
                element.addEventListener("drop", this.onDrop);
                element.addEventListener("dragend", (e) => {
                  e.preventDefault();
                  document.querySelector(
                    ".document_watermark_container_dropable"
                  ).style.zIndex = 0;
                });
                element.addEventListener("drop", this.onDrop);
                element.addEventListener("dragover", this.dragover);
                this.renderTools();
              }}
              boxes={this.state.boxes}
              user={this.state.selectedUser}
              setBoxes={this.setBoxes}
              setActive={this.setActive}
              saveUp={this.state.saveup}
              allUsers={this.state.allUserx}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  boxAdd,
};

const mapStateToProps = (state) => {
  return {
    wholeState: state,
    contract: state.contract,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(example);
