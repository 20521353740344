import React from "react";
import { getStore, custom, axios, Loading } from "@/main";
import { Input, notification } from "antd";
import { BookFilled, CloseCircleFilled } from "@ant-design/icons";
import { SelectContacts, CheckRole } from "@/comps/upload/";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { connect } from "react-redux";
import {
  partsReset,
  partsAdd,
  roleChange,
  partsRemove,
} from "../../store/actions";
import { Translate } from "../../translate";
class PerPart extends React.Component {
  render() {
    let { per, provided, removable, remove, onChange, contact } = this.props;
    let { innerRef, draggableProps, dragHandleProps } = provided;
    let style = { ...draggableProps.style, ...{ borderLeftColor: per.color } };
    let disabled = per.user_id ? true : false;
    return (
      <div
        removable={removable}
        ref={innerRef}
        {...draggableProps}
        {...dragHandleProps}
        style={style}
        className="per_part"
        key={per.key}
        role={per.role}
      >
        <div className="role_container">
          <CheckRole
            per={per.role}
            onChange={(active) => {
              onChange("actionrole", active.actionrole);
              onChange("can_sign", active.key === 0 ? true : false);
            }}
          />
        </div>
        <div className="input_title" role="email">
          Mobile ID&nbsp;<span style={{ color: "red" }}>*</span>
        </div>
        <div className="input_body" role="email">
          <Input
            type="text"
            value={per.msisdn}
            onChange={(a) => {
              onChange("msisdn", a.target.value);
            }}
            placeholder="Phone number"
            disabled={disabled}
          />
        </div>
        <div className="input_title">Email</div>
        <div className="input_body">
          <Input
            type="text"
            value={per.email}
            onChange={(a) => {
              onChange("email", a.target.value);
            }}
            placeholder="User email address"
            disabled={disabled}
            suffix={
              <div className="input_suffix flex_center" onClick={contact}>
                <BookFilled />
              </div>
            }
          />
        </div>
        <div className="remove_button" onClick={remove}>
          <div className="flex_center">
            <CloseCircleFilled />
          </div>
        </div>
      </div>
    );
  }
}

class example extends React.Component {
  constructor(props) {
    super(props);
    this.color = getStore("color");
    this.user = getStore("user");
    this.roles = getStore("roles");
    this.parts = this.props.wholeState.contract.parts;
    this.contract = this.props.wholeState.contract;
  }
  state = {
    reload: 0,
    contact: false,
    contact_index: 0,
    loading: false,
    sysConts: [],
    // selectedUser: this.props.parts[0],
  };
  reload = () => {
    this.setState({ reload: this.state.reload + 1 });
  };
  componentDidMount() {
    //console.log("getting sysconts");
    this.getSysContacts();
  }
  getSysContacts = () => {
    this.setState({ loading: true });
    axios
      .post("client/api/user/contacts", {
        page: 0,
        pper: 10,
        search: "",
      })
      .then((e) => {
        e.data.contacts.map((per, i) => {
          per.key = i + 1;
        });
        //console.log("thesysconts", e.data.contacts);
        this.setState({
          sysConts: e.data.contacts.sort((a, b) => {
            return b.id - a.id;
          }),
        });
        //this.setState({ loading: false });
      })
      .catch((data) => {
        this.setState({ sysConts: [] });
        notification.error({ message: data.message });
        this.setState({ loading: false });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  mainindex = 1;
  parts = [];
  funcs = {
    add: () => {
      if (this.props.data.parts.length < 10) {
        let { parts } = this.props.data;
        this.mainindex++;
        //console.log("theparts here", parts);
        parts.push({
          key: this.mainindex,
          msisdn: "",
          email: "",
          user_id: 0,
          color: this.color[this.mainindex % this.color.length],
          bg_color: this.color[this.mainindex % this.color.length].replace(
            ", 1)",
            ", 0.7)"
          ),
          actionrole: this.roles[0].actionrole,
          can_sign: true,
          move_sign: false,
          coords: [],
        });
      } else {
        notification.warning({ message: "Max limit is 10!" });
      }
      this.reload();
    },
    remove: (per, i) => {
      let { parts } = this.props.data;
      parts.splice(i, 1);
      this.reload();
    },
    init: () => {
      let { data } = this.props;
      if (data.parts.length === 0) {
        this.funcs.add();
      }
      this.reload();
    },
    ondrop: (e) => {
      if (!e.destination) {
        return;
      }
      if (e.destination.index === e.source.index) {
        return;
      }
      let { parts } = this.props.data;
      parts = custom.arraymove(parts, e.source.index, e.destination.index);
      this.reload();
    },
    contact: (per, i) => {
      this.setState({ contact: true, contact_index: i });
    },
    onSelect: (per) => {
      let { contact_index } = this.state;
      let { parts } = this.props.data;
      console.log("onselect working here", parts);
      let active = parts[contact_index];
      active.msisdn = per.msisdn;
      active.email = per.email_address;
      active.name = per.name;
      active.user_id = per.id;
      active.disable = true;
      active.kdss_user = per.kdss_user;
      this.setState({ contact: false });
    },
    finish: () => {
      let p = this.props.params;
      //console.log("onFinish working here", p);
      this.props.onChange(this.props.data.parts);
      window.location.hash = `#/${p.main}/${p.mainfilter}/${p.pop}/${
        p.popfilter * 1 + 1
      }`;
    },
    sign_add: () => {
      console.log("the syscon", this.state.sysConts);
      let { docs_id, parts } = this.props.data;
      console.log("checking parts here", parts);
      console.log("signadd working here", parts);

      if (parts.some((el) => el.msisdn.length === 0))
        notification.warning({
          message: "Please fill the all participants",
          duration: 2,
        });
      else {
        this.setState({ loading: true });
        parts.map((el, idx) => {
          el.order = idx + 1;
        });
        parts.map((el, idx) => {
          this.state.sysConts.map((cont, id) => {
            if (el.msisdn[0] == "3") {
              if (el.msisdn == cont.kdss_msisdn) {
                el.name = cont.kdss_name;
                el.user_id = cont.kdss_user;
                el.kdss_user = cont.kdss_user;
              }
            } else {
              //let pre = '976';
              let allx = "976" + el.msisdn;

              if (allx == cont.kdss_msisdn) {
                el.name = cont.kdss_name;
                el.user_id = cont.kdss_user;
                el.kdss_user = cont.kdss_user;
              }
            }
          });
        });

        axios
          .post("client/api/doc/signer/add", {
            docid: docs_id,
            recs: parts,
          })
          .then((e) => {
            this.setState({ loading: false });
            this.funcs.finish();
          })
          .catch((e) => {
            this.setState({ loading: false });
            notification["error"]({
              message: "Алдаа гарлаа (fu)",
              description: e.data.message,
            });
          });
      }
    },
  };
  componentDidUpdate(pp, ps) {
    if (
      pp.params.popfilter != this.props.params.popfilter &&
      this.props.params.popfilter == 1
    ) {
      this.funcs.init();
    }
    if (pp.trigger !== this.props.trigger) {
      this.funcs.sign_add();
    }
  }
  render() {
    let { params, data, back } = this.props;
    let { user } = this;
    let { parts } = data;
    //console.log("render working here", parts);
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");
    if (params.popfilter != 1) {
      return <div />;
    }
    return (
      <>
        <Loading loading={this.state.loading} />
        <div className="step1_container relafull">
          <SelectContacts
            visible={this.state.contact}
            onCancel={() => {
              this.setState({ contact: false });
            }}
            onOk={this.funcs.onSelect}
          />
          <div className="step1_detail">
            <div
              className="btn flex_center"
              bg_color="5"
              onClick={this.funcs.add}
            >
              {Translate[ln].NewDoc.Participants.AddPart}
            </div>
            <div className="step1_user_detail" style={{ marginTop: 20 }}>
              <div className="step1_user_detail_title">
                {" "}
                {Translate[ln].NewDoc.Participants.Sender}
              </div>
              <div className="step1_user_detail_body">
                <div className="user_dropdown_name">{user.kdss_name}</div>
                <div className="user_dropdown_mobile">{user.kdss_msisdn}</div>
                <div className="user_dropdown_email" color="1">
                  {user.kdss_email}
                </div>
              </div>
            </div>
            <div className="parts_scroll_area">
              <div className="step1_user_detail" style={{ marginTop: 20 }}>
                <div className="step1_user_detail_title">
                  {" "}
                  {Translate[ln].NewDoc.Participants.FileDetails}
                </div>
                <div
                  className="step1_user_detail_body"
                  style={{ paddingBottom: 10 }}
                >
                  <div className="user_dropdown_name">
                    {" "}
                    {Translate[ln].NewDoc.Participants.Filename}
                  </div>
                  <div className="user_dropdown_mobile">{data.file.name}</div>
                  <div className="user_dropdown_name">
                    {Translate[ln].NewDoc.Participants.Filesize}
                  </div>
                  <div className="user_dropdown_mobile">
                    {custom.currency(data.file.size / 1000)}KB
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="step1_body">
            <div className="absfull">
              <DragDropContext onDragEnd={this.funcs.ondrop}>
                <div className="step_title">
                  {/* {parts.length === 1
                    ? `Participant (${parts.length})`
                    : `Participants (${parts.length})`} */}
                  {Translate[ln].NewDoc.Participants.Title}({parts.length})
                </div>
                <div className="parts_container">
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {parts.map((per, i) => {
                          return (
                            <Draggable
                              key={per.key + "c" + i}
                              draggableId={"drag_" + per.key}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <PerPart
                                  removable={parts.length > 1 ? 1 : 0}
                                  onChange={(key, val) => {
                                    parts[i][key] = val;
                                    this.reload();
                                  }}
                                  remove={() => {
                                    this.funcs.remove(per, i);
                                  }}
                                  contact={() => {
                                    this.funcs.contact(per, i);
                                  }}
                                  provided={provided}
                                  per={per}
                                />
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wholeState: state,
  };
};

export default connect(mapStateToProps)(example);
