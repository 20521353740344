import React from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { axios, setStore } from "@/main";
import { RedirectRoute, RecoverRoute, LoginRoute, MainRoute } from "@/comps";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./store/reducers";
import promiseMiddleware from "redux-promise";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreMiddleware = composeEnhancer(
  applyMiddleware(promiseMiddleware)
)(createStore);

class example extends React.Component {
  state = {
    loading: true,
  };
  funcs = {
    info: () => {
      axios
        .post("client/api/user/info", {})
        .then((a) => {
          setStore("user", a.data.detail);
          console.log("thedetail", a.data.detail);
          this.funcs.end_load();
        })
        .catch((a) => {
          this.funcs.end_load();
        });
    },
    end_load: () => {
      document.querySelector("#preload")?.setAttribute("loading", "0");
      this.setState({ loading: false });
    },
  };
  componentDidMount() {
    this.funcs.info();
  }
  render() {
    let { loading } = this.state;
    if (loading) {
      return <div />;
    }
    return (
      <Provider store={createStoreMiddleware(reducers)}>
        <div className="main relafull">
          <Router>
            <Switch>
              <Route path="/login" component={LoginRoute} />
              <Route path="/recover" component={RecoverRoute} />
              <Route path="/redirect" component={RedirectRoute} />
              <Route
                path="/:main/:mainfilter/:pop/:popfilter"
                component={MainRoute}
              >
                {/* <MainRoute {...this.props}/> */}
              </Route>
              <Redirect to="/home/0/0/0" />
            </Switch>
          </Router>
          <div className="clear_div" />
        </div>
      </Provider>
    );
  }
}

export default example;
