import React from "react";
import { Loading, axios } from "@/main";
import { Filter } from "@/comps/home/";
import { notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStore } from "../../main";
import { Translate } from "../../translate";

class example extends React.Component {
  state = {
    loading: false,
  };
  filters = [
    {
      key: 0,
      title: "Complete",
      body: "0",
      icon: "check-circle",
      color: "green",
      href: "#/manage/3/0/0",
    },
    {
      key: 1,
      title: "Received",
      body: "0",
      icon: "inbox",
      color: "orange",
      href: "#/manage/1/0/0",
    },
    {
      key: 2,
      title: "Archive",
      body: "0",
      icon: "archive",
      color: "#ed3755 ",
      href: "#/manage/2/0/0",
    },
  ];
  funcs = {
    init: () => {
      this.setState({ loading: true });
      axios
        .post("client/api/doc/home", {})
        .then((e) => {
          this.filters[0].body = e.data.count.complete;
          this.filters[1].body = e.data.count.received;
          this.filters[2].body = e.data.count.archived;
          this.setState({ loading: false });
        })
        .catch((e) => {
          this.setState({ loading: false });
          notification["error"]({
            message: "Алдаа гарлаа (fu)",
            description: e.data.message,
          });
        });
    },
  };
  componentDidMount() {
    this.funcs.init();
  }

  render() {
    let { loading } = this.state;
    let style = { marginRight: 20 };
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");
    return (
      <div className="home_router_container">
        <Loading loading={loading} />
        <div className="mini_title">{Translate[ln].Home.Alltime}</div>
        <div className="home_filter_container">
          {this.filters.map((per, i) => {
            return (
              <Filter
                key={per.key}
                style={style}
                data={per}
                name={Translate[ln].Home.Filters[per.title]}
              />
            );
          })}
          <div className="trees_container">
            <FontAwesomeIcon
              icon={["fas", "tree"]}
              className="card_icon"
              color="rgb(20, 161, 15)"
            />
            <span>4000</span>
            <div>{Translate[ln].Home.Filters.Trees}</div>
          </div>
          <div className="trees_container">
            <FontAwesomeIcon
              icon={["fas", "tint"]}
              className="card_icon"
              color="#1975ee"
            />
            <span>2.3M l</span>
            <div> {Translate[ln].Home.Filters.Water}</div>
          </div>
        </div>
        <div className="mini_title" style={{ paddingTop: 20 }}>
          {Translate[ln].Home.Thismonth}
        </div>
        <div className="home_filter_container">
          {this.filters.map((per, i) => {
            return (
              <Filter
                key={per.key}
                style={style}
                data={per}
                name={Translate[ln].Home.Filters[per.title]}
              />
            );
          })}
          <div className="trees_container">
            <FontAwesomeIcon
              icon={["fas", "tree"]}
              className="card_icon"
              color="rgb(20, 161, 15)"
            />
            <span>4000</span>
            <div>{Translate[ln].Home.Filters.Trees}</div>
          </div>
          <div className="trees_container">
            <FontAwesomeIcon
              icon={["fas", "tint"]}
              className="card_icon"
              color="#1975ee"
            />
            <span>2.3M l</span>
            <div>{Translate[ln].Home.Filters.Water}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default example;
