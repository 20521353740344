import axios from "axios";
import custom from "./custom.jsx";
import { getStore, setStore } from "@/main/global.jsx";
import { notification } from "antd";
// import https from "https"

const axioos = axios.create();
// const hostname = getStore("hostname");
const origin = getStore("origin");
axioos.defaults.timeout = 180000;
axioos.defaults.baseURL = `${origin}/`;
axioos.defaults.headers.common["Authorization"] = custom.getCookie("token");

const lang = window.localStorage.getItem("vsign_lang") || "en";
axioos.defaults.headers.common["Accept-Language"] = lang;
// axioos.defaults.httpsAgent = new https.Agent({
//   rejectUnauthorized: false,
// });

if (process.env.NODE_ENV === "development") {
  axioos.defaults.baseURL = "https://127.0.0.1:8000/";
}

let reset = function () {
  axioos.defaults.headers.common["Authorization"] = custom.getCookie("token");
};
axioos.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      // Initial load skips error notification.
      const user = getStore("user");
      if (user.detail !== "init") {
        notification["error"]({
          message: "Error acquired. (fu)",
          description: error.response?.data?.message,
        });
      }
      const isRecover = window.location.hash.slice(2, 9) === "recover";
      const isRedirect = window.location.hash.slice(2, 10) === "redirect";
      if (isRedirect) {
        console.log("redirecting");
      }
      if (isRecover) {
        window.location.hash = window.location.hash;
        return "";
      }
      custom.setCookie("redirect_url", window.location.hash);
      if (custom.getUrlParameter("msisdn")) {
        window.location.hash =
          "#/login/mobile?msisdn=" + custom.getUrlParameter("msisdn");
      } else {
        if (custom.getUrlParameter("email")) {
          window.location.hash =
            "#/login/email?email=" + custom.getUrlParameter("email");
        } else {
          window.location.hash = "#/login/mobile";
        }
      }
      return Promise.reject({
        data: { message: error.response?.data?.message },
      });
    }
    if (error.response?.status === 500) {
      return Promise.reject({
        data: { message: "System error acquired. (fu)" },
      });
    }
    return Promise.reject(error.response);
  }
);

export { axioos as axios, reset };

// Уучлаарай системийн алдаа.
