import React from "react";
import { axios, Loading } from "@/main";
import { notification } from "antd";
import { getStore } from "../../main";
import { Translate } from "../../translate";

class Step4 extends React.Component {
  state = {
    subject: "",
    desc: "",
    loading: false,
  };
  finish = () => {
    this.setState({ loading: true });
    let { docs_id, txn_id } = this.props.data;
    let json = {
      subject: this.state.subject,
      desc: this.state.desc,
      docid: docs_id,
      txnid: txn_id,
    };
    axios
      .post("client/api/doc/startsign", json)
      .then((e) => {
        notification["success"]({
          message: "Амжилттай",
          description: e.data.message,
        });
        this.setState({ loading: false });
        window.location.hash = "#/home";
      })
      .catch((e) => {
        notification["error"]({
          message: "Алдаа гарлаа (fu)",
          description: e.data.message,
        });
        this.setState({ loading: false });
      });
  };
  componentDidUpdate(pp, ps) {
    if (pp.trigger !== this.props.trigger) {
      this.finish();
    }

    if (this.props.flag) {
      if (ps.subject !== this.state.subject) {
        this.props.onChange({
          desc: this.state.desc,
          subject: this.state.subject,
        });
      }

      if (ps.desc !== this.state.desc) {
        this.props.onChange({
          desc: this.state.desc,
          subject: this.state.subject,
        });
      }
    }
  }
  render() {
    let { loading, subject, desc } = this.state;
    let { params, trigger, triggerKey } = this.props;
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");
    // const t = trigger ? trigger : 4;
    // const _key = triggerKey ? triggerKey : "popfilter";

    // if (params[_key] !== t) {
    //   return <div />;
    // }
    return (
      <div className="relafull">
        <Loading loading={loading} />
        <div className="upload_steps_body">
          <div className="upload_steps_add_rec">
            <div className="upload_steps_add_rec_title">
              {Translate[ln].NewDoc.Finish.RevnSend}
            </div>
            <div
              className="upload_steps_add_rec_title"
              style={{ fontSize: 18, paddingTop: 0 }}
            >
              {Translate[ln].NewDoc.Finish.Recip}
            </div>
            <div className="upload_steps_add_rec_per_title">
              {Translate[ln].NewDoc.Finish.Email}{" "}
              <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ paddingBottom: 20 }}>
              <input
                type="text"
                className="upload_steps_add_rec_per_input"
                maxLength={255}
                value={subject}
                onChange={(a) => {
                  this.setState({ subject: a.target.value });
                }}
                placeholder="Subject"
              />
            </div>
            <div className="upload_steps_add_rec_per_title">
              {" "}
              {Translate[ln].NewDoc.Finish.EmailMessage}
            </div>
            <div>
              <textarea
                className="upload_steps_add_rec_per_input"
                value={desc}
                onChange={(a) => {
                  this.setState({ desc: a.target.value });
                }}
                style={{
                  paddingTop: 10,
                  resize: "none",
                  paddingBottom: 10,
                  height: 100,
                }}
                placeholder="Enter message"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step4;
