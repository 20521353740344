import React from "react";
import { axios, Loading } from "@/main";
import { DragDrop } from "@/comps/upload";
import { notification } from "antd";

class example extends React.Component {
  state = {
    loading: false,
    stamps: [],
  };
  funcs = {
    finish: () => {
      let p = this.props.params;
      window.location.hash = `#/${p.main}/${p.mainfilter}/${p.pop}/${
        p.popfilter * 1 + 1
      }`;
    },
    addstamp: () => {
      this.setState({ loading: true });
      let { docs_id, parts } = this.props.data;
      // let coords = [];
      // parts.map((per,i)=>{
      //     per.coords.map((per1,i1)=>{
      //         let stamp = {...{},...per1};
      //         stamp["key"] = i;
      //         stamp["msisdn"] = per.msisdn;
      //         coords.push(stamp);
      //     })
      // })
      let box = document.querySelector(".document_watermark_container");
      console.log("check here step2 stamps", this.state.stamps);
      let json = {
        docid: docs_id,
        stamps: this.state.stamps,
        pagewidth: box.clientWidth,
        pageheight: box.clientHeight,
      };
      // Hey bro check here

      axios
        .post("client/api/doc/stamp/add", json)
        .then(() => {
          this.setState({ loading: false });
          this.props.setData(this.state.stamps);
          this.funcs.finish();
        })
        .catch(({ data }) => {
          this.setState({ loading: false });
          notification["error"]({
            message: data.message,
            // description:e.data.message
          });
        });
    },
  };

  // check here setStamp
  setStamps = (data) => {
    this.setState({ stamps: data });
    this.props.setStamps(data);
  };
  componentDidUpdate(pp, ps) {
    if (pp.trigger !== this.props.trigger) {
      this.funcs.addstamp();
    }
  }
  render() {
    let { data, params, start } = this.props;
    let { file, parts } = this.props.data;
    if (params.popfilter != 2) {
      return <div />;
    }
    if (parts.length === 0) {
      start();
      return <div />;
    }
    return (
      <>
        <Loading loading={this.state.loading} />
        <div className="step1_container relafull">
          <DragDrop
            file={file}
            parts={parts}
            setStamps={this.setStamps}
            stamps={this.props.stamps}
          />
        </div>
      </>
    );
  }
}

export default example;
